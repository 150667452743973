import FileManager from '@/General/FileManager.js'
import DocumentTypeModel from '@/Modules/Document/DocumentType/DocumentTypeModel.js'
import UserModel from '@/Modules/User/UserModel.js'
import _ from 'lodash'
import { i18n } from '@/Setup/SetupI18n.js'

export default class DocumentModel {
  id = null
  name = null
  fileName = null
  filePath = null
  fileType = null
  fileSize = null
  isUploaded = false
  documentableId = null
  isFolder = false
  depth = 0
  checked = false
  data = null

  user = null
  type = null

  constructor(data = {}) {
    _.forEach(data, (value, field) => {
      this[_.camelCase(field)] = value
    })

    this.user = data.user && new UserModel(data.user)
    this.type = data.type && new DocumentTypeModel(data.type)
  }

  hasBulkActions() {
    return !this.isFolder
  }

  description() {
    let action = i18n.tc('common.created')
    if (this.isFolder) {
      return `${action} ${i18n.tc('common.on')} ${this.createdAt}`
    }
    action = this.isUploaded
      ? i18n.tc('documents.uploaded-by')
      : i18n.tc('documents.generated-by')
    const user = this.user ? this.user.name : i18n.tc('documents.system')

    return `${action} ${user} ${i18n.tc('documents.on')} ${this.createdAt} `
  }

  fileTypeIconName() {
    if (this.isFolder) {
      return FileManager.getFolderTypeIcon()
    }
    return FileManager.getFileTypeIcon(this.fileName)
  }

  getFileType() {
    if (_.isString(this.filePath)) {
      return !!this.filePath.match(/^\/drive\//i) ? null : this.fileType
    }
    return ''
  }

  signatureStatus() {
    return _.get(this.data, 'sign_status')?.replace('_', ' ')
  }
}
